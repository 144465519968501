import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const PractitionersVideo = () => {
  const [popup, setPopup] = useState(-1);
  const practitioners = [
    {
      name: "Rohit Sharma",
      link: "https://www.youtube.com/embed/bQYZyUs-ZNE",
      image: (
        <StaticImage
          src="../../assets/images/rohit.jpg"
          alt="Rohit"
          style={{ flex: 1 }}
        />
      ),
    },
    {
      name: "PV Sindhu",
      link: "https://www.youtube.com/embed/q2QjKAde74U",
      image: (
        <StaticImage
          src="../../assets/images/pvsindhu.jpg"
          alt="PV Sindhu"
          style={{ flex: 1 }}
        />
      ),
    },
    {
      name: "Captain",
      link: "https://www.youtube.com/embed/mi7wuIffnog",
      image: (
        <StaticImage
          src="../../assets/images/captain.jpg"
          alt="Captain"
          style={{ flex: 1 }}
        />
      ),
    },
  ];

  return (
    <div className="practitioner_wrapper">
      <div className="container">
        <h2>How people feel about Heartfulness</h2>
        <div className="box_wrapper">
          {practitioners.map((item, idx) => (
            <div
              className="box_area"
              role="button"
              tabIndex={0}
              onClick={() => setPopup(idx === 2 ? -1 : idx)}
              onKeyDown={() => setPopup(idx === 2 ? -1 : idx)}
              key={item.name}
            >
              {/* <iframe src={item.link} frameborder="0" title="Bhandara" /> */}
              {item.image}
            </div>
          ))}
        </div>
      </div>
      <Modal
        className="full-width-video-popup"
        show={popup !== -1}
        onHide={() => setPopup(-1)}
        centered
      >
        <Modal.Body>
          <div
            className="video_popup_close"
            role="button"
            tabIndex={0}
            onClick={() => setPopup(-1)}
            onKeyDown={() => setPopup(-1)}
          >
            <div className="close" />
          </div>
          <div className="video_popup_wrapper">
            <iframe
              src={practitioners[popup]?.link}
              allowFullScreen="allowfullscreen"
              loading="lazy"
              allow="autoplay; fullscreen; picture-in-picture"
              title={practitioners[popup]?.name}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PractitionersVideo;
