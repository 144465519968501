import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Container, Navbar, Nav } from "react-bootstrap";
import logo from "../assets/images/heartfulness-logo.svg";

const Header = ({ isSticky }) => {
  return (
    <header
      id="header"
      className={`header-section ${isSticky ? "" : "sticky"}`}
    >
      <Navbar expand="xl" variant="dark">
        <Container>
          <div className="logo-wrapper">
            <Link
              to="https://heartfulness.org/"
              style={{ color: "transparent" }}
            >
              <Navbar.Brand to="/bhandara/" className="logo-section">
                <img src={logo} alt="Logo" style={{ cursor: "pointer" }} />
              </Navbar.Brand>
            </Link>
            <div className="d-flex" style={{ gap: "20px" }}>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
          </div>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <ul className="main-menu">
                <li>
                  <a
                    activeClassName="nav_link_active"
                    className="nav-link nlb"
                    href="https://heartfulness.org/kanha/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    home
                  </a>
                </li>
                <li>
                  <span className="nav-link nlb" style={{ cursor: "pointer" }}>
                    about
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        activeClassName="nav_link_active"
                        className="nav-link"
                        href="https://heartfulness.org/kanha/about"
                        target="_blank"
                        rel="noreferrer"
                      >
                        About Kanha
                      </a>
                    </li>
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        className="nav-link"
                        href="https://heartfulness.org/kanha/gallery"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Gallery
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="nav-link nlb" style={{ cursor: "pointer" }}>
                    visit kanha
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        className="nav-link"
                        href="https://heartfulness.org/kanha/book-a-stay"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Stay facilities
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    className="nav-link nlb"
                    href="https://heartfulness.org/kanha/service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facilities
                  </Link>
                </li>
                <li className="nlb_container">
                  <span className="nav-link nlb" style={{ cursor: "pointer" }}>
                    initiatives
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        className="nav-link"
                        href="https://heartfulness.org/kanha/green-initiatives"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Green Initiatives
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClassName="nav_link_active"
                        className="nav-link"
                        href="https://heartfulness.org/kanha/blue-initiatives"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Blue Initiatives
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    className="nav-link nlb"
                    href="https://heartfulness.org/kanha/contact-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact us
                  </Link>
                </li>
                <li>
                  <a
                    className="nav-link"
                    href="https://donations.heartfulness.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    donate
                  </a>
                </li>
                <li>
                  <Link
                    activeClassName="nav_link_active"
                    className="nav-link nlb"
                    href="https://heartfulness.org/kanha/shop"
                    target="_blank"
                    rel="noreferrer"
                  >
                    shop
                  </Link>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.defaultProps = {
  isSticky: `false`,
};

Header.propTypes = {
  isSticky: PropTypes.bool,
};

export default Header;
