import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const JoinUsTo = () => {
  return (
    <div className="join_us_wrpper">
      <div className="container">
        <div className="join_us_inner_wrapper">
          <div className="flex_1">
            <h2>Join Us To...</h2>
            <ul>
              <li>Experience inner joy and contentment</li>
              <li>Discover your potential</li>
              <li>Upgrade your existence</li>
            </ul>
          </div>
          <StaticImage
            src="../../assets/images/jointous.jpg"
            alt="kanha shanti vanam location"
            placeholder="blurred"
            style={{ flex: 1 }}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinUsTo;
