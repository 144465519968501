import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, isSticky, wrapperClass }) => {
  useEffect(() => {}, []);
  return (
    <div className={wrapperClass}>
      <Header isSticky={isSticky} />
      <main className="main-wrapper" id="main-wrapper">
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  isSticky: "false",
  wrapperClass: "",
};

Layout.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  isSticky: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

export default Layout;
