import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  const [show, setShow] = useState(false);
  const mapLink =
    "https://www.google.com/maps/place/Kanha+Shanti+Vanam/@17.175162,78.2167481,17z/data=!4m5!3m4!1s0x3bcbc79cefda0263:0x70187761cc42b51a!8m2!3d17.1751569!4d78.2189368?shorturl=1";

  useEffect(() => {
    const scrollfunc = () => {
      if (
        document.body.scrollTop > 220 ||
        document.documentElement.scrollTop > 220
      ) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    document.addEventListener("scroll", scrollfunc);
    return () => document.removeEventListener("scroll", scrollfunc);
  });

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari.
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <footer className="footer-wrapper">
      <div className="footer-section">
        <div className="container">
          {show && (
            <div
              className="scroll-to-top scrollToTop"
              id="scrollToTop"
              onKeyDown={() => scrollToTop()}
              onClick={() => scrollToTop()}
              role="button"
              tabIndex="0"
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </div>
          )}
          <div className="row">
            <div className="col-md-3">
              <div className="contact-section">
                <a href={mapLink} target="_blank" rel="noreferrer noopener">
                  <StaticImage
                    src="../assets/images/location.png"
                    alt="kanha shanti vanam location"
                    style={{ cursor: "pointer" }}
                  />
                  <span>
                    <FontAwesomeIcon icon={faExpandAlt} />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-md-2">
              <div className="footer-widget">
                <h3>Links</h3>
                <div className="link-wrapper-section">
                  <div className="link-wrapper">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://heartfulness.org/kanha/"
                        >
                          home
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://heartfulness.org/kanha/about"
                        >
                          about
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://heartfulness.org/kanha/events"
                        >
                          events
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://heartfulness.org/kanha/service"
                        >
                          services
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://heartfulness.org/kanha/contact-us"
                        >
                          contact us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-widget">
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <a
                      href="https://www.daaji.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      daaji
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://heartfulness.org/in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      heartfulness
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.heartfulnessmagazine.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      heartfulness magazine
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://heartfulness.org/kanha/green-initiatives"
                    >
                      green kanha
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://heartfulness.org/kanha/book-a-stay"
                    >
                      accommodation
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://donations.heartfulness.org/donation-general-fund"
                      target="_blank"
                      rel="noreferrer"
                    >
                      donate
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-widget contact-content">
                <h3>Contact</h3>
                <address>
                  <p>
                    13-110, Kanha Shanti Vanam
                    <br /> Kanha Village, Ranga Reddy District,
                    <br /> Nandigama Mandal,
                    <br /> Telangana 509325
                  </p>
                  <p>
                    <a
                      href="mailto:welcome@heartfulness.org"
                      rel="noreferrer"
                      target="_blank"
                    >
                      welcome@heartfulness.org
                    </a>
                  </p>
                  <p>1800 103 7726</p>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-youtube" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/kanhashantivanam/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/kanhashantivanam"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon-facebook" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-8 links">
              <ul>
                <li>
                  <a
                    href="https://heartfulness.org/en/terms/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="https://hfn.link/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy policy
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
