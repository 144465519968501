import React from "react";
import { googleForm } from "../../assets/data/config";

const Banner = () => {
  return (
    <div className="bhandara_banner">
      <div className="video-overlay">
        <div className="container">
          <div className="row banner_text_height">
            <div className="col-12 col-md-12 pt-4">
              <div className="intro-content">
                <h1 className="banner_title_text">
                  Join Millions Meditating For A Better World
                </h1>
                <h3 className="banner_sub_title_text">
                  The way to peace starts within each of us
                </h3>
                <div>
                  <h6>Meditation Timings (IST) :</h6>
                  <ul className="bandara_list">
                    <li>29 April - 6:30 PM</li>
                    <li>30 April - 6:30 AM, 11:00 AM, 6:30 PM</li>
                    <li>1 May - 6:30 AM</li>
                  </ul>
                </div>
                <a
                  className="navigate_btn"
                  href={googleForm.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Register for the event
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {}
      <video
        className="myVideoNew"
        playsinline="playsinline"
        autoPlay="autoplay"
        muted="muted"
        loop="loop"
      >
        <source
          type="video/mp4"
          src="https://cdn-prod.heartfulness.org/videos/A309C118_1901059O_CANON.mp4"
        />
        <track kind="captions" />
      </video>
    </div>
  );
};

export default Banner;
