import React from "react";
import Layout from "../layout";
import "../assets/styles/home.scss";
import Banner from "../components/home/Banner";
import Section2 from "../components/home/Section2";
import JoinUsTo from "../components/home/JoinUsTo";
import PractitionersVideo from "../components/home/PractitionersVideo";
import Seo from "../components/Seo";

const Bhandara = () => {
  return (
    <Layout>
      <div>
        <Seo
          title="Bhandara registration - April 2022"
          description="Bhandara registration - April 2022"
        />
        <Banner />
        <Section2 />
        <JoinUsTo />
        <PractitionersVideo />
      </div>
    </Layout>
  );
};

export default Bhandara;
