import applyProdQaConfig from "../../../../utils/applyProdQaConfig";

export const eventId = applyProdQaConfig({
  prod: {
    id: "b8517361-f9ed-4fc1-8609-2347be87414c",
  },
  qa: {
    id: "e1db75e8-2e54-45fb-977a-7989e1d2f7a9",
  },
});

export const googleForm = applyProdQaConfig({
  prod: {
    link: "https://docs.google.com/forms/d/e/1FAIpQLSed1lOtq6lNPNfyPU5HttVxhY77xjdRdBZjUpB5fBgIl4Hp-A/viewform",
  },
  qa: {
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfctKIXSm8HvpiqRKZW24MpwoBAYZrIupuCBYkiohNsfvSOMw/viewform",
  },
});

const config = {
  ref: "B99999999",
  event: eventId.id,
  email: null,
  name: "",
  mobile: "",
  pnr: "",
  reg_json: {},
  communication_preference: 1,
};

export default config;
