import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { googleForm } from "../../assets/data/config";

const Section2 = () => {
  return (
    <div className="section2_wrapper">
      <div className="container">
        <div className="card_layer">
          <StaticImage
            src="../../assets/images/image1.jpg"
            alt="kanha shanti vanam location"
            style={{ flex: 1 }}
          />
          <div className="content">
            <h4>The way to peace starts within each of us</h4>
          </div>
        </div>
        <div className="card_layer reverse">
          <StaticImage
            src="../../assets/images/image3.jpg"
            alt="kanha shanti vanam location"
            style={{ flex: 1 }}
          />
          <div className="content">
            <h4>
              Celebrating 123 Years of the World’s Free Meditation Resource
            </h4>
            <a
              className="navigate_btn"
              href={googleForm.link}
              target="_blank"
              rel="noreferrer"
            >
              Register for the event
            </a>

            <h6>29th, 30th April and 1st May</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
